import React, { useEffect, useState } from 'react';
import SSRHead from 'components/SSR/SSRHead';
import dynamic from 'next/dynamic';
import HeaderLayout from './HeaderLayout';
// import FooterLayout from './FooterLayout';
import FourOFourPage from 'utility/templates/ErrorPage/404Page';
import HK from 'factory/modules/HK';
import { connect } from 'react-redux';
import LoaderLayout from './LoaderLayout';
import RudderStackAnalytics from 'factory/modules/RudderStackAnalytics';
import { withRouter } from 'next/router';
import { __fireBaseInit } from 'factory/Analytics/Firebase';
import { __dataLayerAdded, __getCookie, __registeredAddress, __sessionStorageDel, __sessionStorageGet, __sessionStorageSet, __setCookie } from 'factory/modules/HK_service';
import config from 'factory/modules/config';
const Utility = dynamic(() => import('components/common/misc/utility'), { ssr: false });

const PageLayout = ({ ...props }) => {
  const [isFirstLoad, set_isFirstLoad] = useState(true);
  useEffect(() => {
    if (typeof window != 'undefined') {
      window.pageType = props.pageType;
      HK.updateScreenName();
      // HK.isMobile() && HK.chatIcon(false);
      // HK.isMobile() && HK.showOpenInAppBar(false);
    }
    __fireBaseInit();
    RudderStackAnalytics.SEND_EVENT("page");
    if (props.pageType && props.pageType !== 'paymentSuccess') {
      if (isFirstLoad && HK.loggedIn()) {
        const isReturning = __sessionStorageGet('isReturning');
        if (!isReturning) {
          getrecentOrdersummary();
        } else {
          updateUserDatalayer(true);
        }
      } else {
        if (!HK.loggedIn()) {
          const useragent = navigator.userAgent || navigator.vendor || window.opera;
          const eventData = {
            useragent: useragent
          };
          __dataLayerAdded(eventData);
          setTimeout(() => {
            set_isFirstLoad(false);
          }, 500);
        }
      }
    }
  }, []);
  useEffect(() => {
    const isLoggedin = props.serverIsLoggedin || props.isLoggedin;
    if (isLoggedin && !isFirstLoad) {
      if (props.pageType && props.pageType !== 'paymentSuccess') {
        const isReturning = __sessionStorageGet('isReturning');
        if (!isReturning) {
          getrecentOrdersummary();
        } else {
          updateUserDatalayer(true);
        }
      }

    }
  }, [props.serverIsLoggedin, props.isLoggedin]);
  const getUniqueKey = () => {
    let result = "";
    const _queryParam = props.query;
    const _requestUrl = props.router && props.router.asPath;
    const _pageType = props.pageType;
    let _key = "";
    const BLACKLIST_PAGES = process.env.NEXT_PUBLIC_CRITTERS_BLACKLIST_URLS;
    const CACHE_ENABLED = process.env.NEXT_PUBLIC_CRITTERS_CACHE_ENABLE;
    // console.log("QueryParam: ", _queryParam);
    // console.log("RequestURL: ", _requestUrl);
    if (!CACHE_ENABLED) {
      return "";
    }
    if (BLACKLIST_PAGES && _requestUrl) {
      const _pagesArr = BLACKLIST_PAGES.split(",");
      const _requestUrlMinimized = _requestUrl.split("?")[0];
      if (_pagesArr.includes(_requestUrlMinimized)) {
        return "";
      }
    }
    try {
      if (_pageType) {
        _key = _pageType.replace(/ /g, '_');
      } else if (_requestUrl) {
        const _requestUrlMinimized = _requestUrl.split("?")[0];
        if (_requestUrlMinimized === "/") {
          _key = 'home';
        } else {
          _key = _requestUrlMinimized.replace(/\//g, '_');
        }
      } else {
        return "";
      }
      if (_queryParam && _queryParam["PURGE_ALL_CRITICAL_CACHE"] === "true") {
        return "PURGE_ALL_CRITICAL_CACHE";
      }
      if (_queryParam && _queryParam["PURGE_CRITICAL_CACHE"] === "true") {
        _key = "PURGE_CRITICAL_CACHE_" + _key;
      }
      result = `${_key}_${props.isMobile ? 'mobile' : 'desktop'}_${(props.serverIsLoggedin || props.isLoggedin) ? 'logged_in' : 'logged_out'}`;
    } catch (error) {
      console.log("ERROR GETTING UNIQUE KEY: ", error);
      result = "";
    }
    // console.log("UNIQUE_KEY: ", result);
    return result;
  };
  const updateUserDatalayer = (isReturning) => {
    const userType = isReturning ? 'Returning' : 'New';
    let duration;
    const d = new Date();
    const isUserEvent = __sessionStorageGet('isUserEvent');
    if (isUserEvent) {
      const isUserType = __getCookie('userType');
      if (!isUserType) {
        __setCookie("userType", userType, d.getDate() + duration, true);
        setTimeout(() => {
          set_isFirstLoad(false);
        }, 500);
      }
    } else {
      __setCookie("userType", userType, d.getDate() + duration, true);
      const useragent = navigator.userAgent || navigator.vendor || window.opera;
      const eventData = {
        userType: isReturning ? 'Returning' : 'New',
        isReturningUser: isReturning,
        useragent: useragent
      };
      __dataLayerAdded(eventData);
      __sessionStorageSet('isUserEvent', true);
      setTimeout(() => {
        set_isFirstLoad(false);
      }, 500);
    }
  };
  const getrecentOrdersummary = () => {
    __sessionStorageDel('isUserEvent');
    const url = config.APIS.myAccount.recentOrdersummary;
    HK.ajaxCall({
      url: url,
      method: 'get',
    }).then(res => {
      const _res = res && res.data && res.data.results;
      if (_res && !_res.exception) {
        const orders = _res.orders || [];
        let isReturning = false;
        if (orders && orders.length > 0) {
          isReturning = true;
          __sessionStorageSet('isReturning', isReturning);
        }
        updateUserDatalayer(isReturning);
      }
    }).catch(error => {
      console.log(error, 'something went wrong');
    });
  };
  const renderOGSchema = () => {
    return (
      <>
        <div itemscope="" itemType="https://schema.org/Organization" style={{ display: 'none' }}>
          <span itemProp="name">Gritzo</span>
          <link itemProp="url" href="https://www.Gritzo.com" />
          <img itemprop="image" src={HK.getImage('/logos/gritzo-logo.svg')} alt="gritzo logo"></img>
          <div itemscope="" itemProp="contactPoint" itemscop="" itemType="http://schema.org/ContactPoint">
            <meta itemProp="telephone" content="+91 92 054 87 429" />
            <meta itemProp="contactType" content="Customer service" />
          </div>
          <div>
            <a itemProp="sameAs" href="https://www.facebook.com/GritzoIndia">Facebook</a>
            <a itemProp="sameAs" href="https://www.instagram.com/gritzoindia">Instagram</a>
            <a itemProp="sameAs" href="https://twitter.com/Gritzo">Twitter</a>
            <a itemProp="sameAs" href="https://www.youtube.com/channel/UChKtlpPT6MX2iDfZ586BHxg">Youtube</a>
          </div>
          <div itemProp="address" itemscope="" itemType="https://schema.org/PostalAddress">
            <span itemProp="streetAddress">
              {__registeredAddress()}
            </span>
            <span itemProp="addressRegion">India</span>
            <span itemProp="postalCode">110019</span>
            <span itemProp="telephone">+91 92 054 87 429</span>
          </div>
        </div>
      </>
    );
  };

  const { routeLoading, pageName, isMobile, show404, children } = props;
  const router = props.router;
  return (<div className="main-container-wrapper page_layout_nxt" data-pagetype={getUniqueKey()}>
    {!show404 && <SSRHead {...props} router={router} />}
    {/* <main> */}
    {!props.noHeader ? <HeaderLayout isMobile={isMobile} {...props} /> : ''}
    {/* ################### 
                  do not change id const_web_main_container required for SEO caching 
                  ########################## */}
    <div id='const_web_main_container' className={!isMobile ? "page-layout-desktop react-page-container" : "page-layout-mob react-page-container"}>
      {renderOGSchema()}
      {
        show404
          ?
          <FourOFourPage />
          :
          <LoaderLayout
            pageName={pageName}
            isMobile={isMobile}
            routeLoading={routeLoading}
          >
            {
              children
            }
          </LoaderLayout>
      }
    </div>
    {!props.noFooter ? <Utility hideFooter={props.hideFooter} /> : ''}
    {/* </main> */}
    {/* <footer>
                <FooterLayout isMobile={props.isMobile} />
            </footer> */}
  </div>
  );
};

function mapStateToProps(state) {
  return {
    isLoggedin: state.isLogedInReducer
  };
}

export default withRouter(connect(mapStateToProps, null)(PageLayout));