import React, { Component } from 'react';
import MobileHeader from 'components/common/header/MobileHeader';
import DesktopHeader from 'components/common/header/desktop/';
import HK from 'factory/modules/HK';
// store imports
import { bindActionCreators } from 'redux';
import { headerMenuAction, MenuNodeAction } from 'components/common/header/action';
import { connect } from 'react-redux';
import CartMenu from 'components/common/header/contains/CartMenu';
import ProfileMenu from 'components/common/header/contains/ProfileMenu';
import MenuStrip from 'components/common/header/desktop/MenuStrip';
import SingleLineHeaderContainer from 'components/common/header/SingleLineHeader/SingleLineHeaderContainer';
class HeaderWidget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuIndex: null,
			menuDataLoaded: false,
			isCloseBtn: false,
			pageType: null,
			isCheckoutScreen: false,
			popupType: null,
			productMenuData: '',
			isFeedbackScreen:false
		}
	}
	HideFooterMenu = () => {
		this.props._headerMenuAction(null);
	}
	UNSAFE_componentWillReceiveProps = () => {
		let copyState = { ...this.state }
		if (window.location.pathname === '/checkout') {
			copyState.isCloseBtn = true;
			copyState.pageType = 'checkout';
			copyState.popupType = 'checkout';
			copyState.isCheckoutScreen = true
		} else {
			copyState.isCloseBtn = true;
			copyState.pageType = 'cart';
			copyState.popupType = null;
			copyState.isCheckoutScreen = false
		}
		if (window.location.pathname === '/feedback/Feedback.action') {
			copyState.isFeedbackScreen = true;
		} else {
			copyState.isFeedbackScreen = false;
		}

		this.setState(copyState)
	}
	menuHandler = (index, event) => {

		let menuIndex = this.props._MenuIndex
		if (menuIndex == index) {
			menuIndex = null
		} else {
			if (HK.isMobile(this.props) && index == 'cartmenu') {
				menuIndex = null;
				window.location.href = '/cart/Cart.action';
				// this.props.history.push('/cart/Cart.action')
			} else {
				menuIndex = index
			}
		}
		var body = document.body;
		if (!HK.isMobile(this.props)) {
			if (menuIndex) {
				body.classList.add("overflow-hidden")
			} else {
				body.classList.remove("overflow-hidden")
			}
		}
		this.props._headerMenuAction(menuIndex);
	}

	componentDidMount(){
		if(this.props._pageTypeReducer !== "cart"){
			this.props._headerMenuAction(null);
		}
	}

	render() {
		return (
			this.props._singleHeaderReducer !== 'hidden' &&
			<>
				<div className="hk-header-wrapper">
				{!this.props.isMobile && this.props._menuPromotionalDataReducer && <MenuStrip promotionData={this.props._menuPromotionalDataReducer}/>}
					{
						this.props._MenuIndex === 'cartmenu' && window.pageType !== 'cart' &&
						<CartMenu
							className={!this.state.isCloseBtn ? 'bg-white no-footer checkout-layer gritzo-cart-menu' : 'bg-white gritzo-cart-menu'}
							clickHandler={this.menuHandler.bind(this, null)}
							menuIndex={this.props._MenuIndex}
							pageType={this.state.pageType}
							isCartPopup={true}
							isCloseBtn={this.state.isCloseBtn}
							popupType={this.state.popupType}
							isCheckoutScreen={this.state.isCheckoutScreen}
							selectedAddressId={this.props._ActiveAdddressReducer || 0}>
						</CartMenu>
					}
					{this.props._MenuIndex == 'profilemenu' && <ProfileMenu clickHandler={this.menuHandler.bind(this, null)} menuIndex={this.props._MenuIndex}></ProfileMenu>}
					{
						this.props._singleHeaderReducer
							?
							<SingleLineHeaderContainer linkTo={this.props._singleHeaderReducerLinkTo} {...this.props} />
							:
							(
								<>
									{/* {this.state.isFeedbackScreen?'':<PromotionalTextStrip data={this.props._headerPromotionalTextReducer} />} */}
									{
										!this.props.isMobile ?
											<DesktopHeader isMobile={this.props.isMobile} cartHandler={this.menuHandler.bind(this, 'cartmenu')} profileHandler={this.menuHandler.bind(this, 'profilemenu')}  {...this.props} />
											:
											<MobileHeader isMobile={this.props.isMobile} cartHandler={this.menuHandler.bind(this, 'cartmenu')} HideFooterMenu={this.HideFooterMenu.bind(this)} />
									}
								</>

							)
					}
				</div>
			</>
		)
	}
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		_headerMenuAction: headerMenuAction,
		_MenuNodeAction: MenuNodeAction
	}, dispatch);
}
function mapStateToProps(state) {
	return {
		_singleHeaderReducer: state.singleLineHeaderReducer,
		_singleHeaderReducerLinkTo: state.singleLineHeaderLinkToReducer,
		_MenuIndex: state.MenuReducer.menuIndex,
		_headerPromotionalTextReducer: state.headerPromotionalTextReducer,
		_ActiveAdddressReducer: state.ActiveAdddressReducer,
		_menuPromotionalDataReducer: state.menuPromotionalDataReducer,
		_pageTypeReducer: state.pageTypeReducer
	}
}
export default (
	connect(mapStateToProps, mapDispatchToProps)(HeaderWidget)
);
