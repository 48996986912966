import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';

let remoteConfigVar;
export const fireBaseInitForRemoteConfig = (app) => {
    try {
        if (app && typeof window !== "undefined") {
            remoteConfigVar = getRemoteConfig(app);
            remoteConfigVar.settings.minimumFetchIntervalMillis = 10000;
        }
    } catch (error) {
        console.log("Error:", error);
    }
};

export const keyBasedConfigExtractor = (key) => {
    return fetchAndActivate(remoteConfigVar).then(() => getValue(remoteConfigVar, key)).catch((err) => console.log(err));
};

export const remoteConfig = remoteConfigVar;